import { differenceInHours, startOfWeek, endOfWeek } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';


export const calculateOpsParameters = (location, opsDate) => {
	const start_date = startOfWeek(opsDate);
	const end_date = endOfWeek(opsDate);

	const prev_start_date = new Date(start_date);
	prev_start_date.setDate(prev_start_date.getDate() - 7);

	const prev_end_date = new Date(end_date);
	prev_end_date.setDate(prev_end_date.getDate() - 7);

	return {
		start_date: format(start_date, 'yyyy-MM-dd'),
		end_date: format(end_date, 'yyyy-MM-dd'),
		prev_start_date: format(prev_start_date, 'yyyy-MM-dd'),
		prev_end_date: format(prev_end_date, 'yyyy-MM-dd'),
		store: location.lookup_name,
		aggregate: "24_hours"
	};
}


export const calculateParameters = (location, startDate, endDate) => {
	// Start and end dates are chosen from the calendar picker
	const start_date = format(startDate, "yyyy-MM-dd");
	const end_date = format(endDate, "yyyy-MM-dd");

	// Calculate the start_time and end_time
	const start_time = "00:00";
	let end_time = "23:59:59";

	// Are we in the same day?
	const sameDay = new Date().toDateString() === endDate.toDateString();

	if (sameDay) {
		// Calculate the end DateTime in the store's timezone
		const storesEndDT = utcToZonedTime(new Date(), location.timezone);

		// Check if the stores EndDT wrapped over to the next day
		if (new Date().toDateString() === storesEndDT.toDateString)
			end_time = "23:59:59";

		// Set the stores end_time back 30 minutes to account for data processing time
		else {
			storesEndDT.setMinutes(storesEndDT.getMinutes() - 30);
			storesEndDT.setMinutes(storesEndDT.getMinutes() - storesEndDT.getMinutes() % 30);
			end_time = format(storesEndDT, "HH:mm");
		}
	}

	// Need to convert these to parameters
	const max_cashier_time = 2 * 60;    // 2 minutes
	const max_queue_time = 2 * 60;      // 2 minutes
	const max_pump_time = 20 * 60;      // 20 minutes
	const max_parking_time = 20 * 60;   // 20 minutes

	// build the post body to request the data
	const aggregate = calculateAggregate(start_date, start_time, end_date, end_time);

	return {
		store: location.lookup_name,
		start_date, start_time,
		end_date, end_time,
		aggregate: aggregate.to_api,
		max_cashier_time,
		max_queue_time,
		max_pump_time,
		max_parking_time
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
// function: calculateAggregate
// parameters:
//  start_date: string (YYYY-MM-DD)
//  start_time: string (HH-MM)
//  end_date: string (YYYY-MM-DD)
//  end_time: string (HH-MM)
//
export function calculateAggregate(start_date, start_time, end_date, end_time) {
	const startDate = new Date(start_date + " " + start_time);
	const endDate = new Date(end_date + " " + end_time);

	const hours = differenceInHours(endDate, startDate);

	if (hours < 23) {
		return { amount: 30, unit: 'minutes', to_api: '0.5_hours' };
	}

	else if (hours <= 71) {
		return { amount: 1, unit: 'hours', to_api: '1_hour' };
	}

	return { amount: 24, unit: 'hours', to_api: '24_hours' };
}
