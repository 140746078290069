import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useAppContext } from 'src/contexts/AppContext';


export default function StoreSelector() {
    const { filteredLocations, selectedLocation, setSelectedLocation } = useAppContext();

    return (
        <Autocomplete
            disablePortal
            disableClearable
            id="location-selector"
            value={ selectedLocation }
            isOptionEqualToValue={ (option, value) => option.id === value.id }
            options={ filteredLocations }
            sx={{ width: 175 }}
            onChange={ (e, value) => setSelectedLocation(value)}
            renderInput={(params) => <TextField {...params} label="Location" />}
        />
    );
}
