import { Suspense, lazy } from 'react';
import LoadingScreen from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('src/pages/auth/LoginPage')));

// ANALYTICS
export const Cashier = Loadable(lazy(() => import('src/pages/analytics/Cashier')));
export const Pumps = Loadable(lazy(() => import('src/pages/analytics/Pumps')));
export const Parking = Loadable(lazy(() => import('src/pages/analytics/Parking')));
export const Ops = Loadable(lazy(() => import('src/pages/analytics/Ops')));
export const CoffeeBar = Loadable(lazy(() => import('src/pages/analytics/CoffeeBar')));
export const Heatmap = Loadable(lazy(() => import('src/pages/analytics/Heatmap')));

// MAIN
export const Page500 = Loadable(lazy(() => import('src/pages/Page500')));
export const Page403 = Loadable(lazy(() => import('src/pages/Page403')));
export const Page404 = Loadable(lazy(() => import('src/pages/Page404')));

