import './locales/i18n';
import 'simplebar/src/simplebar.css'

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { SettingsProvider } from './components/settings';
import ScrollToTop from './components/scroll-to-top';

import { AuthProvider } from './auth/Auth0Context';

import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import App from './app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { LicenseInfo } from '@mui/x-license-pro';
import { AppProvider } from './contexts/AppContext';


LicenseInfo.setLicenseKey('ccbf09ffa355984b32eccfaed37581c5Tz01MjczOCxFPTE2OTc3NTQ3NjEyNjYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 60 * 1000 * 5, // 5 min
      staleTime: Infinity
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <HelmetProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <BrowserRouter basename="/retail">
                <ScrollToTop />
                <App />
                <ReactQueryDevtools initialIsOpen={false} />
              </BrowserRouter>
            </SettingsProvider>
          </LocalizationProvider>
        </HelmetProvider>
      </AppProvider>
    </QueryClientProvider>
  </AuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
