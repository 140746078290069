import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { startOfWeek, endOfWeek, format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { useAppContext } from 'src/contexts/AppContext';


const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

export default function DateRangeSelectionOps() {
  const { opsDate, setOpsDate } = useAppContext();

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!opsDate) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = startOfWeek(opsDate);
    const end = endOfWeek(opsDate);

    const newDate = new Date(format(date, "yyyy-MM-dd"));
    const newStart = new Date(format(start, "yyyy-MM-dd"));
    const newEnd = new Date(format(end, "yyyy-MM-dd"));

    const dayIsBetween = newDate.getTime() >= newStart.getTime() && newDate.getTime() <= newEnd.getTime();
    const isFirstDay = newDate.getTime() === newStart.getTime();
    const isLastDay = newDate.getTime() === newEnd.getTime();

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disableFuture
        label="Week picker"
        value={startOfWeek(opsDate)}
        onChange={(newValue) => {
          setOpsDate(newValue);
        }}
        renderDay={renderWeekPickerDay}
        renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} {...params} />}
        inputFormat="'Week of' MMM d"
      />
    </LocalizationProvider>
  );
}
