import { useQuery } from 'react-query';
import { variables } from "src/variables";

export const useInitialization = (id_token, options) => {
  return useQuery(["init"], () =>
    fetch(variables().central + 'users/init', {
      method: 'post',
      body: JSON.stringify({ id_token: `${id_token}`, client_ts: "2024-03-12 12:23:23" })
    }).then(res => res.json()),
    options
  );
}
