import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { utcToZonedTime, format } from 'date-fns-tz';
import { useAppContext } from 'src/contexts/AppContext';


const DisplayStoreTime = () => {
    const [intervalId, setIntervalId] = useState(null);
    const [timezone, setTimezone] = useState("America/Los_Angeles");
    const [time, setTime] = useState(new Date());
    const { locations, selectedLocation } = useAppContext();

    useEffect(() => {
      const tz = locations?.find((x) => x?.id === selectedLocation?.id)?.timezone;

      if (tz)
        setTimezone(tz);
    }, [selectedLocation]);

    const updateTime = () => {
      setTime(utcToZonedTime(new Date(), timezone));
    }
    useEffect(() => {
      clearInterval(intervalId);
      setIntervalId(setInterval(() => {
        updateTime();
      }, 1000));
      return () => clearInterval(intervalId);
    }, [timezone]);

    return (
      <Typography align="center">Store Time: {format(time, "HH:mm:ss")}</Typography>
    );
};

export default DisplayStoreTime;
