import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { useAppContext } from "src/contexts/AppContext";

export default function DateRangeSelection() {
  const { dates, setDates } = useAppContext();
  const [localDates, setLocalDates] = useState(dates);
  // const [setTimezone] = useState("America/New_York");

  // useEffect(() => {
  //   setTimezone(locations.find((x) => x.id === selectedLocation.id)?.timezone);
  // }, [selectedLocation, locations]);

  const handleDateChange = (newValue) => {
    // Convert the selected dates to the desired time zone
    // const convertedDates = newValue.map((date) =>
    //   zonedTimeToUtc(date, timezone)
    // );

    // if (convertedDates.length === 2) {
    //   const secondDate = convertedDates[1];
    //   const updatedSecondDate = addMinutes(addHours(secondDate, 23), 59);
    //   convertedDates[1] = updatedSecondDate;
    // }

    // Dates in the database are already in local store time, so no need to convert the dates
    setDates(newValue);
    setLocalDates(newValue);
  };
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={{ start: "Start Date", end: "End Date" }}
    >
      <DateRangePicker
        value={localDates}
        calendars={1}
        disableFuture
        onAccept={handleDateChange}
        onChange={(newValue) => setLocalDates(newValue)}
        renderInput={(startProps, endProps) => (
          <>
            <TextField
              onKeyDown={(e) => e.preventDefault()}
              {...startProps}
              style={{ width: 175 }}
            />
            <Box
              sx={{
                mx: 2,
                color: (theme) =>
                  theme.palette.mode === "light" ? "grey.800" : "common.white",
              }}
            >
              {" "}
              to{" "}
            </Box>
            <TextField
              onKeyDown={(e) => e.preventDefault()}
              {...endProps}
              style={{ width: 175 }}
            />
          </>
        )}
      />
    </LocalizationProvider>
  );
}
