import { Navigate, useRoutes } from 'react-router-dom';

import AuthGuard from 'src/auth/AuthGuard';
import GuestGuard from 'src/auth/GuestGuard';

import CompactLayout from 'src/layouts/compact';
import DashboardLayout from 'src/layouts/dashboard';

import { PATH_AFTER_LOGIN } from 'src/config';

import {
  LoginPage,
  Cashier,
  Pumps,
  Parking,
  Ops,
  CoffeeBar,
  Heatmap,
  Page500,
  Page403,
  Page404,
} from './elements';


export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
      ],
    },
    {
      path: 'analytics',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'cashier', element: <Cashier /> },
        { path: 'pumps', element: <Pumps /> },
        { path: 'parking', element: <Parking /> },
        { path: 'ops', element: <Ops /> },
        // { path: 'heatmap', element: <Heatmap /> },
        // { path: 'coffee-bar', element: <CoffeeBar /> },
      ]
    },
    {
      element: <CompactLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '/', element: <Navigate to={PATH_AFTER_LOGIN} replace /> },
    // { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
