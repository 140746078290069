import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';
import { useAppContext } from 'src/contexts/AppContext';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Container } from '@mui/material';


export default function StoreFilterDialog() {
  const featuresArray = [
    {name: "Cashier", value: true},
    {name: "Pumps", value: false},
    {name: "Parking", value: false},
    {name: "Coffee Bar", value: false},
    {name: "Kitchen", value: false},
    {name: "Corridor", value: false},
    {name: "FSA", value: false},
    {name: "Shop Assist", value: false},
  ];

  const { markets, locations, filteredLocations, setFilteredLocations } = useAppContext();
  const [showDialog, setShowDialog] = useState(false);
  const [features, setFeatures] = useState(featuresArray);
  const [filteredMarket, setFilteredMarket] = useState("All Markets");

  const filterLocations = () => {
    const data = locations.filter(location => {
      if (filteredMarket !== "All Markets" && location.market.name !== filteredMarket)
        return false;

      for (const i in features) {
        if (features[i].value && !location.features.some((x) => x.name === features[i].name))
          return false;
      }

      return true;
    });

    setFilteredLocations(data.map(x => { return { id: x.id, label: x.location }}));
  }

  useEffect(() => {
    setFilteredLocations(locations.map((location) => { return { id: location.id, label: location.location } }));
  }, [locations]);

  // Filters locations whenever the features or market change
  useEffect(() => {
    filterLocations();
  }, [filteredMarket, features])

  const handleClose = () => {
    setShowDialog(false);
  };

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  }

  const handleChange = (e) => {
    setFeatures(features.map(feature => (feature.name === e.target.name) ? { name: e.target.name, value: !feature.value } : feature));
  }

  const changeMarket = (e, value) => {
    setFilteredMarket(value);
  }

  return (
    <>
      <FilterAltOutlinedIcon onClick={toggleDialog} />
      <Dialog open={showDialog} onClose={handleClose} maxWidth={"sm"} fullWidth={true}>
        <DialogTitle style={{ textAlign: 'center', fontSize: 23 }}>Store Filter ({filteredLocations.length} / {locations.length})</DialogTitle>
        <DialogContent>
          <Container style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginBottom: 35 }}>
            <Autocomplete
              disableClearable
              id="markets-parameters"
              options={markets}
              value={filteredMarket}
              onChange={changeMarket}
              sx={{ width: 175 }}
              style={{ alignSelf: 'flex-end' }}
              renderInput={(params) => <TextField {...params} label="Markets" />}
            />
          </Container>
          <Box noValidate component="form" sx={{ display: 'flex', flexDirection: 'column', m: 'auto' }}>
            <FormGroup>
              <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                  <FormControlLabel control={<Switch checked={features.find(x => x.name === "Cashier").value} name="Cashier" onChange={handleChange} />} label="Cashier" />
                  <FormControlLabel control={<Switch checked={features.find(x => x.name === "Pumps").value} name="Pumps" onChange={handleChange} />} label="Pumps" />
                  <FormControlLabel control={<Switch checked={features.find(x => x.name === "Parking").value} name="Parking" onChange={handleChange} />} label="Parking" />
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                  <FormControlLabel control={<Switch checked={features.find(x => x.name === "Coffee Bar").value} name="Coffee Bar" onChange={handleChange} />} label="Coffee Bar" />
                  <FormControlLabel control={<Switch checked={features.find(x => x.name === "Kitchen").value} name="Kitchen" onChange={handleChange} />} label="Kitchen" />
                  <FormControlLabel control={<Switch checked={features.find(x => x.name === "Corridor").value} name="Corridor" onChange={handleChange} />} label="Corridor" />
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                  <FormControlLabel control={<Switch checked={features.find(x => x.name === "FSA").value} name="FSA" onChange={handleChange} />} label="FSA" />
                  <FormControlLabel control={<Switch checked={features.find(x => x.name === "Shop Assist").value} name="Shop Assist" onChange={handleChange} />} label="Shop Assist" />
                </Box>
              </Box>
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
