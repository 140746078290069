// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/retail/auth';
const ROOTS_DASHBOARD = '/analytics';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
};

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  analytics: {
    cashier: path(ROOTS_DASHBOARD, '/cashier'),
    pumps: path(ROOTS_DASHBOARD, '/pumps'),
    parking: path(ROOTS_DASHBOARD, '/parking'),
    ops: path(ROOTS_DASHBOARD, '/ops'),
    coffeeBar: path(ROOTS_DASHBOARD, '/coffee-bar'),
    heatmap: path(ROOTS_DASHBOARD, '/heatmap'),
  }
};
