import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// utils
import { bgBlur } from 'src/utils/cssStyles';
// hooks
import useOffSetTop from 'src/hooks/useOffSetTop';
import useResponsive from 'src/hooks/useResponsive';
// config
import { HEADER, NAV } from 'src/config';
// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { useAppContext } from 'src/contexts/AppContext';

import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import DateRangeSelection from 'src/components/DateRangeSelection';
import StoreSelector from 'src/components/StoreSelector';
import CompanySelector from 'src/components/CompanySelector';
import StoreFilterDialog from 'src/components/StoreFilterDialog';
import DateRangeSelectionOps from 'src/components/DateRangeSelectionOps';
// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const location = useLocation();
  const { isSuperUser } = useAppContext();
  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const renderControls = (path) => {
    if (path === "/analytics/ops") {
      return (
        <>
          {
            isSuperUser && <CompanySelector />
          }
          <DateRangeSelectionOps />
          <StoreSelector />
          <StoreFilterDialog />
        </>
      )
    }
    else if (path === "/analytics/coffee-bar") {
      return null;
    }

    else {
      return (
        <>
          {
            isSuperUser && <CompanySelector />
          }
          <DateRangeSelection />
          <StoreSelector />
          <StoreFilterDialog />
        </>
      )
    }
  }

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      <Stack flexGrow={1} direction="row" alignItems="center" justifyContent="flex-end" spacing={{ xs: 0.5, sm: 1.5 }} mt={2.5}>
        { renderControls(location.pathname) }
        <LanguagePopover />
        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
