import * as React from "react";
import { styled } from "@mui/system";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Button, Container } from "@mui/material";
import { useAppContext } from "src/contexts/AppContext";
import { utcToZonedTime } from "date-fns-tz";
import { useSettingsContext } from "../settings";

const MoreButton = styled(Button)({
  margin: "auto",
  display: "flex",
  fontSize: "10px",
  color: "white",
  backgroundColor: "#212B36",
  marginTop: "-0.9rem",
  border: "0",
  "&:hover": {
    border: "0",
    backgroundColor: "#2A3745",
  },
});

export default function EventAccordion() {
  const { themeStretch } = useSettingsContext();
  const { events, locations, selectedLocation } = useAppContext();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [timezone, setTimezone] = React.useState("America/New_York");

  React.useEffect(() => {
    setTimezone(locations.find((x) => x.id === selectedLocation.id)?.timezone);
  }, [selectedLocation]);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };
  //This component should be on top  of every component, under the header.
  return events === "loading" ? (
    <div></div>
  ) : events === "error" ? (
    <div>Error getting events</div>
  ) : events.length < 1 ? (
    <div></div>
  ) : (
    <Container maxWidth={themeStretch ? false : "xl"}>
      <Accordion
        expanded={isExpanded}
        sx={{
          backgroundColor: "#212B36",
          borderBottomRightRadius: "8px",
          borderBottomLeftRadius: "8px",
        }}
      >
        {
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <WarningAmberIcon
              sx={{
                marginRight: "1rem",
                marginLeft: "0.5rem",
                color: "#FFC700",
              }}
            ></WarningAmberIcon>
            <Typography
              sx={{
                width: "15%",
                fontSize: "18px",
                fontWeight: "700",
                alignItems: "center",
                marginRight: "1rem",
              }}
            >
              {events[0][3]}
            </Typography>
            <Typography
              sx={{
                color: "text.secondary",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                fontWeight: "700",
                width: "15%",
              }}
            >
              START DATE - END DATE:
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
                width: "35%",
                fontWeight: "400",
              }}
            >
              {`${new Date(parseInt(events[0][1]) * 1000).toLocaleDateString(
                "en-US",
                {
                  timeZone: timezone,
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )} - ${new Date(parseInt(events[0][2]) * 1000).toLocaleDateString(
                "en-US",
                {
                  timeZone: timezone,
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )}`}
            </Typography>
            <Typography
              sx={{
                color: "text.secondary",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                fontWeight: "700",
              }}
            >
              DESCRIPTION:
            </Typography>
            <Typography
              noWrap
              sx={{
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                marginLeft: "0.5rem",
                flexShrink: 0,
                fontWeight: "400",
                width: "40%",
              }}
            >
              {events[0][4]}
            </Typography>
          </AccordionSummary>
        }
        {events.length > 1
          ? events.slice(1, events.length).map((event) => (
              <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <WarningAmberIcon
                  sx={{
                    marginRight: "1rem",
                    marginLeft: "0.5rem",
                    color: "#FFC700",
                  }}
                ></WarningAmberIcon>
                <Typography
                  sx={{
                    width: "15%",
                    fontSize: "18px",
                    fontWeight: "700",
                    alignItems: "center",
                    marginRight: "1rem",
                  }}
                >
                  {event[3]}
                </Typography>
                <Typography
                  sx={{
                    color: "text.secondary",
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "700",
                    width: "15%",
                  }}
                >
                  START DATE - END DATE:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                    width: "35%",
                    fontWeight: "400",
                  }}
                >
                  {`${new Date(parseInt(event[1]) * 1000).toLocaleDateString(
                    "en-US",
                    {
                      timeZone: timezone,
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )} - ${new Date(parseInt(event[2]) * 1000).toLocaleDateString(
                    "en-US",
                    {
                      timeZone: timezone,
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )}`}
                </Typography>
                <Typography
                  sx={{
                    color: "text.secondary",
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "700",
                  }}
                >
                  DESCRIPTION:
                </Typography>
                <Typography
                  noWrap
                  sx={{
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "0.5rem",
                    flexShrink: 0,
                    fontWeight: "400",
                    width: "40%",
                  }}
                >
                  {event[4]}
                </Typography>
              </AccordionSummary>
            ))
          : null}
      </Accordion>
      {events.length > 1 ? (
        <MoreButton variant="outlined" onClick={handleClick}>
          {isExpanded ? "- LESS" : "+ MORE"}
        </MoreButton>
      ) : (
        <div style={{ marginBottom: "10px" }}></div>
      )}
    </Container>
  );
}
