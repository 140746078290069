import TextField from '@mui/material/TextField';
import { variables } from "src/variables";
import Autocomplete from '@mui/material/Autocomplete';
import { useAppContext } from 'src/contexts/AppContext';
import { useNavigate } from 'react-router';


export default function CompanySelector() {
    const { authToken, company, companies } = useAppContext();
    const navigate = useNavigate();
    const setCompany = (id) => {
        fetch(variables().backend + `/set_company`, {
        method: 'post',
        body: JSON.stringify({companyId: `${id}`}),
        headers: { Authorization: `Bearer ${authToken}` }
      }).then(() => navigate(0));
    };

    return (
        <Autocomplete
            disablePortal
            disableClearable
            id="company-selector"
            value={ company }
            isOptionEqualToValue={ (option, value) => option.id === value.id }
            options={ companies }
            sx={{ width: 175 }}
            onChange={ (e, value) => setCompany(value.id)}
            renderInput={(params) => <TextField {...params} label="Company" />}
        />
    );
}
