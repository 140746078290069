import PropTypes from 'prop-types';
// @mui
import { Stack } from '@mui/material';
//
import { StyledRoot, StyledContent } from './styles';

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default function LoginLayout({ children }) {
  return (
    <StyledRoot>
      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
