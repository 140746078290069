import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Stack } from '@mui/material';
import { useAuthContext } from 'src/auth/useAuthContext';
import { LoadingButton } from '@mui/lab';

import LoginLayout from 'src/layouts/login';
import Logo from 'src/components/logo';


export default function LoginPage() {
  const { login } = useAuthContext();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    await login();
  };
  return (
    <>
      <Helmet>
        <title> Login | Radius AI</title>
      </Helmet>

      <LoginLayout>
        <Stack spacing={2} sx={{ mb: 5, position: 'relative' }} style={{ alignItems: 'center' }}>
          <Logo style={{ width: 500, height: 500, marginBottom: 50 }} />
          <LoadingButton
            onClick={onSubmit}
            fullWidth
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            sx={{
              bgcolor: '#3B65F6',
              color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
              '&:hover': {
                bgcolor: '#407AFD',
                color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
              },
              width: 250
            }}
          >
            Login
          </LoadingButton>
        </Stack>
      </LoginLayout>
    </>
  );
}
