import { useQuery } from 'react-query';
import { variables } from "src/variables";

export const useLocations = (token, options) => {
  return useQuery(["locations"], () =>
    fetch(variables().backend + '/locations', {
      method: 'post',
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => res.json()),
    options
  );
}
