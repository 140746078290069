//////////////////////////////////////////////////////////////////////////////////////
//
// Since react is compiled at build time, it is not possible to inject
//  the API server URI by using environment variables.  Since we want to
//  call different API endpoints in different environments, then we just
//  select the hostname by URL
//

export function variables() {
  const origin = window && window.location && window.location.origin;

  switch (origin) {
    // Production environment
    case "https://retail.radius.ai":
      return {
        domain: "radiusaiinc.us.auth0.com",
        client_id: "c8BqCJpnkC9KeCx4KWEkLHr8BE4ZeNOQ",
        audience: "https://radiusaiinc.us.auth0.com/api/v2/",
        backend: "https://retail.radius.ai/analytics",
        redirectUri: "https://retail.radius.ai/retail",
        central: "https://central.radius.ai/central/api/v1/"
      };

    // New Azure prod environment
    case "https://retail-app.radius.ai":
      return {
        domain: "radiusaiinc.us.auth0.com",
        client_id: "6VjHUILPXj8fys4nt5ZBai6MgpDvYMBy",
        audience: "https://radiusaiinc.us.auth0.com/api/v2/",
        backend: "https://retail-app.radius.ai/analytics",
        redirectUri: "https://retail-app.radius.ai/retail",
        central: "https://central.radius.ai/central/api/v1/"
      };

    // Staging environment
    case "https://retail.stage.radius.ai":
      return {
        domain: "radiusaiinc.us.auth0.com",
        client_id: "c8BqCJpnkC9KeCx4KWEkLHr8BE4ZeNOQ",
        audience: "https://radiusaiinc.us.auth0.com/api/v2/",
        backend: "https://retail.stage.radius.ai/analytics",
        redirectUri: "https://retail.stage.radius.ai/retail",
        central: "https://central.stage.radius.ai/central/api/v1/"
      };

    // New Azure stage testing environment
    case "https://retail-app.stage.radius.ai":
      return {
        domain: "radiusaiinc.us.auth0.com",
        client_id: "6VjHUILPXj8fys4nt5ZBai6MgpDvYMBy",
        audience: "https://radiusaiinc.us.auth0.com/api/v2/",
        backend: "https://retail-app.stage.radius.ai/analytics",
        redirectUri: "https://retail-app.stage.radius.ai/retail",
        central: "https://central.stage.radius.ai/central/api/v1/"
      };

    // dev environment
    case "https://retail.dev.radius.ai":
      return {
        domain: "radiusaiinc.us.auth0.com",
        client_id: "c8BqCJpnkC9KeCx4KWEkLHr8BE4ZeNOQ",
        audience: "https://radiusaiinc.us.auth0.com/api/v2/",
        backend: "https://retail.dev.radius.ai/analytics",
        redirectUri: "https://retail.dev.radius.ai/retail",
        central: "https://central.dev.radius.ai/central/api/v1/"
      };

    // New Azure dev testing environment
    case "https://retail-app.dev.radius.ai":
      return {
        domain: "radiusaiinc.us.auth0.com",
        client_id: "6VjHUILPXj8fys4nt5ZBai6MgpDvYMBy",
        audience: "https://radiusaiinc.us.auth0.com/api/v2/",
        backend: "https://retail-app.dev.radius.ai/analytics",
        redirectUri: "https://retail-app.dev.radius.ai/retail",
        central: "https://central.dev.radius.ai/central/api/v1/"
      };

    // localhost dev environment
    default:
      return {
        domain: "radiusaiinc.us.auth0.com",
        client_id: "c8BqCJpnkC9KeCx4KWEkLHr8BE4ZeNOQ",
        audience: "https://radiusaiinc.us.auth0.com/api/v2/",
        backend: "http://localhost:8084/analytics",
        redirectUri: "http://localhost:8084/retail",
        central: "https://central.dev.radius.ai/central/api/v1/"
      };
  }
}
