import { createContext, useState, useEffect, useContext } from "react";
import { useAuthContext } from "src/auth/useAuthContext";
import { useEvents } from "src/hooks/events";
import { useInitialization } from "src/hooks/init";
import { useLocations } from "src/hooks/locations";
import {
  calculateParameters,
  calculateOpsParameters,
} from "src/utils/parameters";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const { isAuthenticated, user } = useAuthContext();
  const [dates, setDates] = useState([new Date(), new Date()]);
  const [opsDate, setOpsDate] = useState(new Date());
  const [locations, setLocations] = useState([]);
  const [locationsAvailable, setLocationsAvailable] = useState(false);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [parameters, setParameters] = useState(null);
  const [opsParameters, setOpsParameters] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [hasRetailAccess, setHasRetailAccess] = useState(true);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState();
  const [events, setEvents] = useState([]);

  const { data: userData } = useInitialization(user?.id_token, {
    enabled: Boolean(isAuthenticated),
    retry: 3,
  });

  useEffect(() => {
    setIsSuperUser(userData?.is_superadmin);
    setCompanies(
      userData?.companies.map((x) => {
        return { id: x.id, label: x.project_name };
      })
    );
    setCompany({
      id: userData?.company?.id,
      label: userData?.company?.project_name,
    });

    if (userData?.locations) {
      console.log(userData.locations)
      // setLocationsAvailable(true);
      // if (userData.locations.length > 0) {
      //   setLocations(userData.locations);
      //   setFilteredLocations(userData.locations);
      //   setSelectedLocation({
      //     id: userData?.locations[0]?.id,
      //     label: userData?.locations[0]?.display_name,
      //   });
      // }
      // setMarkets(userData.markets);
    }
  }, [userData]);

  const { data: initialData } = useLocations(userData?.jwt_token, {
    enabled: Boolean(userData?.jwt_token),
    retry: 3,
  });

  useEffect(() => {
    if (initialData?.locations) {
      setLocationsAvailable(true);
      if (initialData.locations.length > 0) {
        console.log("initialData: ", initialData.locations)
        setLocations(initialData.locations);
        setFilteredLocations(initialData.locations);
        setSelectedLocation({
          id: initialData?.locations[0]?.id,
          label: initialData?.locations[0]?.location,
        });
      }
      setMarkets(initialData.markets);
    }
  }, [initialData]);

  useEffect(() => {
    setAuthToken(userData?.jwt_token);
    if (userData?.pages) {
      if (!userData.pages.find((x) => x.name === "retail_app_access"))
        setHasRetailAccess(false);
    }
  }, [userData]);

  useEffect(() => {
    if (selectedLocation) {
      updateParameters();
      updateOpsParameters();
    }
  }, [selectedLocation, dates]);

  useEffect(() => {
    if (selectedLocation) {
      updateOpsParameters();
    }
  }, [selectedLocation, opsDate]);

  const eventsList = useEvents(
    userData?.jwt_token,
    {
      enabled: Boolean(selectedLocation?.id) && Boolean(parameters?.store),
      retry: 3,
    },
    parameters?.store,
    dates
  );

  useEffect(() => {
    if ((eventsList.isIdle || eventsList.isLoading) && events !== "loading") {
      setEvents("loading");
    } else if (eventsList.status === "error" && events !== "error") {
      setEvents("error");
    } else if (eventsList.isSuccess) {
      setEvents(eventsList.data.events ? eventsList.data.events : []);
    }
  }, [eventsList]);

  const updateParameters = () => {
    const location = locations.find((x) => x.id === selectedLocation.id);
    if (location) {
      setParameters(calculateParameters(location, dates[0], dates[1]));
    }
  };

  const updateOpsParameters = () => {
    const location = locations.find((x) => x.id === selectedLocation.id);
    if (location) {
      setOpsParameters(calculateOpsParameters(location, opsDate));
    }
  };

  return (
    <AppContext.Provider
      value={{
        authToken,
        dates,
        setDates,
        opsDate,
        setOpsDate,
        isSuperUser,
        locations,
        setLocations,
        locationsAvailable,
        filteredLocations,
        setFilteredLocations,
        selectedLocation,
        setSelectedLocation,
        hasRetailAccess,
        markets,
        parameters,
        opsParameters,
        companies,
        company,
        events,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useAppContext() {
  return useContext(AppContext);
}
