import { useQuery } from "react-query";
import { variables } from "src/variables";

export const useEvents = (token, options, location_name, dates) => {
  return useQuery(
    ["events", dates, location_name === null ? "store1" : location_name],
    () =>
      fetch(variables().backend + "/events", {
        method: "post",
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify({
          start_date: new Date(dates[0]).toISOString(),
          end_date: new Date(dates[1]).toISOString(),
          location_name: location_name === null ? "store1" : location_name,
        }),
      }).then((res) => res.json()),
    options
  );
};
